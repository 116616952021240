export default {
  'site.title': '播个球',
  'ball.all': '全部',
  'ball.football': '足球',
  'ball.basketball': '籃球',
  'live.sport': '運動',
  'live.start_time': '時間',
  'live.home_team_name': '主隊',
  'live.away_team_name': '客隊',
  'live.live': '直播頻道',
  'live.name': '直播源',
  'live.result': '集锦',
  'live.playback': '回放',
  'live.more': '更多',
  'live.tobeupdated': '待更新',
  'foot.statement':
    '特別聲明: 播個球是一個綜合性體育賽事直播導航平臺,我們所有的賽事直播源均由第三方提供,我們不提供任何獨家賽事直播視頻，不制作任何賽事直播數據,請各位網友遵守當地國家法律法規，請勿用於非法用途。如有疑問請與我們取得聯係。',
};
