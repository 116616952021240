import contactus from './fr-FR/contactus';
import menu from './fr-FR/menu';
import index from './fr-FR/index';

export default {
  ...menu,
  ...index,
  ...contactus,
  '404': "Désolé, la page que vous visitez n'existe pas.",
};
