export default {
    'contactus.name': 'O seu nome',
    'contactus.name.tip': 'Por favor indique o seu nome',
    'contactus.phone': 'O seu número de telefone',
    'contactus.phone.tip': 'Introduza o seu número de telefone',
    'contactus.email': 'O seu e-mail',
    'contactus.email.tip': 'Introduza o seu endereço de e-mail',
    'contactus.email.tip1': 'Indique um endereço de e-mail válido',
    'contactus.content': 'O conteúdo da sua mensagem',
    'contactus.content.tip': 'Por favor, introduza o que quer dizer',
    'contactus.sendding': 'A enviar, por favor aguarde.',
    'contactus.success': 'Enviar com sucesso. Obrigado por sua mensagem. Responderemos o mais breve possível',
};
