export default {
    'contactus.name': 'Su nombr',
    'contactus.name.tip': 'por favor, escriba su nombre',
    'contactus.phone': 'Su número de teléfono',
    'contactus.phone.tip': 'por favor, introduzca su número de teléfono',
    'contactus.email': 'tu buzón',
    'contactus.email.tip': 'Por favor, introduzca su dirección de correo electrónico',
    'contactus.email.tip1': 'Por favor, introduce una dirección de correo electrónico válida',
    'contactus.content': 'Contenido de tu mensaje',
    'contactus.content.tip': 'Por favor ingresa lo que quieres decir',
    'contactus.sendding': 'Enviando, por favor espere...',
    'contactus.success': 'Envío exitoso, gracias por tu mensaje, te responderemos lo antes posible',
};
