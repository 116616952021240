export default {
  'site.title': 'www.bogeqiu.com',
  'ball.all': 'semua',
  'ball.football': 'sepak bola',
  'ball.basketball': 'bola basket',
  'live.sport': 'olahraga',
  'live.start_time': 'waktu',
  'live.home_team_name': 'tim tuan rumah',
  'live.away_team_name': 'tim tandang',
  'live.live': 'Saluran langsung',
  'live.name': 'live',
  'live.result': 'Koleksi',
  'live.playback': 'playback',
  'live.more': 'lebih',
  'live.tobeupdated': 'Untuk diperbarui',
  'foot.statement':
    'Pernyataan khusus: Situs web ini adalah platform siaran langsung acara olahraga yang komprehensif. Semua sumber siaran langsung acara kami disediakan oleh pihak ketiga. Kami tidak menyediakan video langsung acara eksklusif apa pun dan tidak menghasilkan data siaran langsung acara apa pun. Harap patuhi data lokal dan nasional hukum dan peraturan., mohon jangan menggunakannya untuk tujuan ilegal. Jika Anda memiliki pertanyaan, silakan hubungi kami.',
};
