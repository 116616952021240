// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  const routes = {"1":{"path":"/","parentId":"ant-design-pro-layout","id":"1"},"2":{"path":"/index","name":"index","parentId":"ant-design-pro-layout","id":"2"},"3":{"path":"/info","parentId":"ant-design-pro-layout","id":"3"},"4":{"path":"/result","name":"result","parentId":"ant-design-pro-layout","id":"4"},"5":{"path":"/contactus","name":"contactus","parentId":"ant-design-pro-layout","id":"5"},"6":{"path":"/404","parentId":"ant-design-pro-layout","id":"6"},"ant-design-pro-layout":{"id":"ant-design-pro-layout","path":"/","isLayout":true}} as const;
  return {
    routes,
    routeComponents: {
'1': React.lazy(() => import(/* webpackChunkName: "p__Index" */'@/pages/Index.tsx')),
'2': React.lazy(() => import(/* webpackChunkName: "p__Index" */'@/pages/Index.tsx')),
'3': React.lazy(() => import(/* webpackChunkName: "p__Info" */'@/pages/Info.tsx')),
'4': React.lazy(() => import(/* webpackChunkName: "p__Result" */'@/pages/Result.tsx')),
'5': React.lazy(() => import(/* webpackChunkName: "p__Contactus__index" */'@/pages/Contactus/index.tsx')),
'6': React.lazy(() => import(/* webpackChunkName: "p__404" */'@/pages/404.tsx')),
'ant-design-pro-layout': React.lazy(() => import(/* webpackChunkName: "t__plugin-layout__Layout" */'G:/devops/repo/start/project/bogeqiu/bogeqiu_frontend_v5/src/.umi-production/plugin-layout/Layout.tsx')),
},
  };
}
