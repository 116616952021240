export default {
  'site.title': 'www.bogeqiu.com',
  'ball.all': '全て',
  'ball.football': 'フットボール',
  'ball.basketball': 'バスケットボール',
  'live.sport': 'スポーツ',
  'live.start_time': '時間',
  'live.home_team_name': 'ホームチーム',
  'live.away_team_name': 'アウェイチーム',
  'live.live': 'ライブチャンネル',
  'live.name': '生放送',
  'live.result': 'にギャザーを付ける',
  'live.playback': '再生',
  'live.more': '詳細',
  'live.tobeupdated': 'アップグレード保留中',
  'foot.statement':
    '特記事項: この Web サイトは、総合的なスポーツ イベントのライブ ブロードキャスト プラットフォームです。当社のすべてのイベント ライブ ブロードキャスト ソースは第三者によって提供されています。独占的なイベント ライブ ビデオの提供やイベント ライブ データの作成は行っておりません。現地の法律および規制に従ってください。 、違法な目的には使用しないでください。ご質問がございましたら、お問い合わせください。',
};
