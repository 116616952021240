export default {
    'contactus.name': '您的姓名',
    'contactus.name.tip': '请输入您的姓名',
    'contactus.phone': '您的手机号码',
    'contactus.phone.tip': '请输入您的手机号码',
    'contactus.email': '您的邮箱',
    'contactus.email.tip': '请输入您的邮箱地址',
    'contactus.email.tip1': '请输入有效的邮箱地址',
    'contactus.content': '您的留言内容',
    'contactus.content.tip': '请输入您要说的话',
    'contactus.sendding': '正在提交, 请稍后...',
    'contactus.success': '提交成功，感谢您的留言，我们将会尽快回复您',
};
