export default {
  'site.title': 'www.bogeqiu.com',
  'ball.all': 'Tutti',
  'ball.football': 'Calcio',
  'ball.basketball': 'Basketball',
  'live.sport': 'sport',
  'live.start_time': 'tempo',
  'live.home_team_name': 'casa',
  'live.away_team_name': 'via',
  'live.live': 'Canale dal vivo',
  'live.name': 'live',
  'live.result': 'Raccolta',
  'live.playback': 'riproduzione',
  'live.more': 'di più',
  'live.tobeupdated': 'Da aggiornare',
  'foot.statement':
    'Dichiarazione speciale: Questo sito web è una piattaforma di live streaming di eventi sportivi completa e tutte le nostre fonti di live streaming di eventi sono fornite da terze parti. Non forniamo video esclusivi di streaming live di eventi o produciamo dati di streaming live di eventi. I netizen sono tenuti a rispettare le leggi e i regolamenti nazionali locali e a non utilizzarli per scopi illegali. Se avete domande, vi preghiamo di contattarci.',
};
