export default {
  'site.title': 'www.bogeqiu.com',
  'ball.all': 'All',
  'ball.football': 'Football',
  'ball.basketball': 'Basketball',
  'live.sport': 'sport',
  'live.start_time': 'time',
  'live.home_team_name': 'home',
  'live.away_team_name': 'away',
  'live.live': 'Live Channel',
  'live.name': 'live',
  'live.result': 'result',
  'live.playback': 'playback',
  'live.more': 'more',
  'live.tobeupdated': 'pending upgrade',
  'foot.statement':
    'Special statement: This website is a comprehensive sports event live streaming platform, and all of our event live streaming sources are provided by third parties. We do not provide any exclusive event live streaming videos or produce any event live streaming data. Netizens are requested to comply with local national laws and regulations and do not use them for illegal purposes. If you have any questions, please contact us.',
};
