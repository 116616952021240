export default {
  'site.title': 'www.bogeqiu.com',
  'ball.all': '모두',
  'ball.football': '축구',
  'ball.basketball': '농구',
  'live.sport': '스포츠',
  'live.start_time': '시간',
  'live.home_team_name': '홈팀',
  'live.away_team_name': '원정팀',
  'live.live': '라이브 채널',
  'live.name': '생방송',
  'live.result': '모음집',
  'live.playback': '재생',
  'live.more': '추가',
  'live.tobeupdated': '업그레이드 보류 중',
  'foot.statement':
    '특별 성명: 본 웹사이트는 포괄적인 스포츠 이벤트 생방송 플랫폼입니다. 당사의 모든 이벤트 생방송 소스는 제3자에 의해 제공됩니다. 우리는 독점적인 이벤트 라이브 비디오를 제공하거나 이벤트 라이브 데이터를 생성하지 않습니다. 현지 법률 및 규정을 준수하십시오. , 불법적인 목적으로 사용하지 말아주세요. 질문이 있으시면 저희에게 연락해주세요.',
};
