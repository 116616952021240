export default {
  'menu.index': 'halaman Depan',
  'menu.football': 'sepak bola hidup',
  'menu.basketball': 'bola basket secara langsung',
  'menu.live': 'skor',
  'menu.contactus': 'Hubungi kami',
  'menu.language': 'peralihan bahasa',
  'menu.skin.classics': 'klasik',
  'menu.skin.pink': 'pink',
};
