export default {
  'menu.index': 'Главная страница',
  'menu.football': 'Футбол в прямом эфире',
  'menu.basketball': 'Баскетбол в прямом эфире',
  'menu.live': 'Счет',
  'menu.contactus': 'Свяжитесь с нами',
  'menu.language': 'Переключение языка',
  'menu.skin.classics': 'Классика',
  'menu.skin.pink': 'Розовый',
};
