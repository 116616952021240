import contactus from './zh-CN/contactus';
import index from './zh-CN/index';
import menu from './zh-CN/menu';

export default {
  ...menu,
  ...index,
  ...contactus,
  '404': '您访问的页面不见了',
};
