export default {
    'contactus.name': 'votre nom',
    'contactus.name.tip': "S'il vous plaît entrez votre nom",
    'contactus.phone': 'Votre numéro de téléphone',
    'contactus.phone.tip': 'Veuillez entrer votre numéro de téléphone',
    'contactus.email': 'votre boîte aux lettres',
    'contactus.email.tip': 'Veuillez entrer votre adresse e-mail',
    'contactus.email.tip1': "S'il vous plaît, mettez une adresse email valide",
    'contactus.content': 'Contenu de votre message',
    'contactus.content.tip': 'Veuillez entrer ce que vous voulez dire',
    'contactus.sendding': 'Soumission, veuillez patienter...',
    'contactus.success': 'Soumission réussie, merci pour votre message, nous vous répondrons dans les plus brefs délais',
};
