export default {
  'site.title': '播个球 - www.bogeqiu.com',
  'ball.all': '全部',
  'ball.football': '足球',
  'ball.basketball': '篮球',
  'live.sport': '运动',
  'live.start_time': '时间',
  'live.home_team_name': '主队',
  'live.away_team_name': '客队',
  'live.live': '直播频道',
  'live.name': '直播源',
  'live.result': '集锦',
  'live.playback': '回放',
  'live.more': '更多',
  'live.tobeupdated': '待更新',
  'foot.statement':
    '特别声明: 播个球是一个综合性体育赛事直播导航平台,我们所有的赛事直播源均由第三方提供,我们不提供任何独家赛事直播视频，不制作任何赛事直播数据,请各位网友遵守当地国家法律法规，请勿用于非法用途。如有疑问请与我们取得联系。',
};
