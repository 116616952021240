export default {
  'menu.index': 'Home',
  'menu.football': 'Calcio dal vivo',
  'menu.basketball': 'Basket live',
  'menu.live': 'punteggio',
  'menu.contactus': 'Contattaci',
  'menu.language': 'Cambio lingua',
  'menu.skin.classics': 'classici',
  'menu.skin.pink': 'rosa',
};
