import contactus from './th-TH/contactus';
import menu from './th-TH/menu';
import index from './th-TH/index';

export default {
  ...menu,
  ...index,
  ...contactus,
  '404': "ขออภัย หน้าเว็บที่คุณเข้าชมไม่มีอยู่",
};
