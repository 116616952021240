export default {
  'menu.index': 'Trang chủ',
  'menu.football': 'Bóng đá trực tiếp',
  'menu.basketball': 'Bóng rổ trực tiếp',
  'menu.live': 'Điểm số',
  'menu.contactus': 'Liên hệ',
  'menu.language': 'Chuyển đổi ngôn ngữ',
  'menu.skin.classics': 'Cổ điển',
  'menu.skin.pink': 'Màu hồng',
};
