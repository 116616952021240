import contactus from './ru-RU/contactus';
import index from './ru-RU/index';
import menu from './ru-RU/menu';

export default {
  ...menu,
  ...index,
  ...contactus,
  '404': "Извините, страница, которую вы посетили, не существует.",
};
