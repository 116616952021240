import contactus from './id-ID/contactus';
import menu from './id-ID/menu';
import index from './id-ID/index';

export default {
  ...menu,
  ...index,
  ...contactus,
  '404': "Maaf, halaman yang kau kunjungi tidak ada.",
};
