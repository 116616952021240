export default {
  'contactus.name': 'Name',
  'contactus.name.tip': 'Please enter your name',
  'contactus.phone': 'phone number',
  'contactus.phone.tip': 'Please enter your phone number',
  'contactus.email': 'Email',
  'contactus.email.tip': 'Please enter your email address',
  'contactus.email.tip1': 'Please enter a valid email address',
  'contactus.content': 'Your message content',
  'contactus.content.tip': 'Please enter what you want to say',
  'contactus.sendding': 'Submitting, please wait',
  'contactus.success':
    'Successfully submitted. Thank you for your message. We will reply to you as soon as possible',
};
