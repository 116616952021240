import contactus from './en-US/contactus';
import menu from './en-US/menu';
import index from './en-US/index';

export default {
  ...menu,
  ...index,
  ...contactus,
  '404': 'Sorry, the page you visited does not exist.',
};
