export default {
  'menu.index': '首頁',
  'menu.football': '足球直播',
  'menu.basketball': '籃球直播',
  'menu.live': '比分',
  'menu.contactus': '聯係我們',
  'menu.language': '語言切換',
  'menu.skin.classics': '经典',
  'menu.skin.pink': '粉嫩',
};
