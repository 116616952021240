import contactus from './ja-JP/contactus';
import menu from './ja-JP/menu';
import index from './ja-JP/index';

export default {
  ...menu,
  ...index,
  ...contactus,
  '404': "申し訳ありませんが、アクセスしたページは存在しません。",
};
