export default {
  'menu.index': 'page de garde',
  'menu.football': 'football en direct',
  'menu.basketball': 'basket en direct',
  'menu.live': 'score',
  'menu.contactus': 'Contactez-nous',
  'menu.language': 'changer de langue',
  'menu.skin.classics': 'Classique',
  'menu.skin.pink': 'Couleur rose',
};
