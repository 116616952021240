export default {
  'site.title': 'www.bogeqiu.com',
  'ball.all': 'Все.',
  'ball.football': 'Футбол',
  'ball.basketball': 'Баскетбол',
  'live.sport': 'Спорт',
  'live.start_time': 'Время',
  'live.home_team_name': 'Хозяин',
  'live.away_team_name': 'Гостевой отряд',
  'live.live': 'Прямой канал',
  'live.name': 'Источник',
  'live.result': 'Коллекция',
  'live.playback': 'Плейбак',
  'live.more': 'Больше.',
  'live.tobeupdated': 'Будет обновлено',
  'foot.statement':
    'Специальное заявление: bogeqiu - это всеобъемлющая платформа для прямой трансляции спортивных мероприятий, все наши источники прямой трансляции событий предоставляются третьими сторонами, мы не предоставляем никаких эксклюзивных видео прямой трансляции событий, не производим никаких данных прямой трансляции событий, пожалуйста, соблюдайте местные национальные законы и правила, не используйте их в незаконных целях.  В случае возникновения сомнений просьба обращаться к нам.',
};
