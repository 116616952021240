export default {
    'contactus.name': 'Ваше имя',
    'contactus.name.tip': 'Введите ваше имя',
    'contactus.phone': 'Номер вашего мобильного.',
    'contactus.phone.tip': 'Пожалуйста, введите номер вашего мобильного.',
    'contactus.email': 'Ваш почтовый ящик',
    'contactus.email.tip': 'Введите адрес вашего почтового ящика',
    'contactus.email.tip1': 'Введите действительный адрес почтового ящика',
    'contactus.content': 'Ваше сообщение',
    'contactus.content.tip': 'Пожалуйста, введите то, что вы хотите сказать.',
    'contactus.sendding': 'Подается, пожалуйста, позже...',
    'contactus.success': 'Отправить успешно, спасибо за сообщение, мы ответим вам как можно скорее',
};
