export default {
  'menu.index': 'Home',
  'menu.football': 'Football live',
  'menu.basketball': 'Basketball live',
  'menu.live': 'score',
  'menu.contactus': 'Contact Us',
  'menu.language': 'Language switching',
  'menu.skin.classics': 'classics',
  'menu.skin.pink': 'pink',
};
