export default {
  'site.title': 'www.bogeqiu.com',
  'ball.all': 'ทั้งหมด',
  'ball.football': 'ฟุตบอล',
  'ball.basketball': 'บาสเกตบอล',
  'live.sport': 'กีฬา',
  'live.start_time': 'เวลา',
  'live.home_team_name': 'ทีมเหย้า',
  'live.away_team_name': 'ทีมเยือน',
  'live.live': 'ช่องถ่ายทอดสด',
  'live.name': 'ถ่ายทอดสด',
  'live.result': 'คอลเลกชัน',
  'live.playback': 'การเล่น',
  'live.more': 'มากกว่า',
  'live.tobeupdated': 'อัปเดต',
  'foot.statement':
    'ข้อความพิเศษ: เว็บไซต์นี้เป็นแพลตฟอร์มถ่ายทอดสดการแข่งขันกีฬาที่ครอบคลุม แหล่งที่มาของการถ่ายทอดสดกิจกรรมทั้งหมดของเราจัดทำโดยบุคคลที่สาม เราไม่ได้จัดทำวิดีโอถ่ายทอดสดกิจกรรมพิเศษใด ๆ หรือสร้างข้อมูลถ่ายทอดสดกิจกรรมใด ๆ โปรดปฏิบัติตามกฎหมายและข้อบังคับท้องถิ่น โปรดอย่าใช้เพื่อวัตถุประสงค์ที่ผิดกฎหมาย หากคุณมีคำถามใด ๆ โปรดติดต่อเรา.',
};
