import contactus from './ko-KR/contactus';
import menu from './ko-KR/menu';
import index from './ko-KR/index';

export default {
  ...menu,
  ...index,
  ...contactus,
  '404': "죄송합니다. 방문한 페이지가 없습니다.",
};
