export default {
  'contactus.name': 'Nome',
  'contactus.name.tip': 'Inserisci il tuo nome',
  'contactus.phone': 'numero di telefono',
  'contactus.phone.tip': 'Inserisci il tuo numero di telefono',
  'contactus.email': 'Email',
  'contactus.email.tip': 'Inserisci il tuo indirizzo email',
  'contactus.email.tip1': 'Inserisci un indirizzo email valido',
  'contactus.content': 'Contenuto del messaggio',
  'contactus.content.tip': 'Inserisci quello che vuoi dire',
  'contactus.sendding': 'Inviare, attendere',
  'contactus.success':
    'Presentato con successo. Grazie per il messaggio. Ti risponderemo al più presto',
};
