import contactus from './zh-TW/contactus';
import menu from './zh-TW/menu';
import index from './zh-TW/index';

export default {
  ...menu,
  ...index,
  ...contactus,
  '404': '您訪問的頁面不見了',
};
