export default {
    'contactus.name': '您的姓名',
    'contactus.name.tip': '請輸入您的姓名',
    'contactus.phone': '您的手機號碼',
    'contactus.phone.tip': '請輸入您的手機號碼',
    'contactus.email': '您的郵箱',
    'contactus.email.tip': '請輸入您的郵箱地址',
    'contactus.email.tip1': '請輸入有效的郵箱地址',
    'contactus.content': '您的留言內容',
    'contactus.content.tip': '請輸入您要說的話',
    'contactus.sendding': '正在提交, 請稍後...',
    'contactus.success': '提交成功，感謝您的留言，我們將會盡快回復您',
};
