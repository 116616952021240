export default {
    'contactus.name': 'Tên của bạn',
    'contactus.name.tip': 'xin hãy nhập tên của bạn',
    'contactus.phone': 'Số điện thoại của bạn',
    'contactus.phone.tip': 'xin vui lòng điền số điện thoại của bạn',
    'contactus.email': 'hộp thư của bạn',
    'contactus.email.tip': 'Hãy điền địa chỉ email của bạn',
    'contactus.email.tip1': 'vui lòng nhập địa chỉ email hợp lệ',
    'contactus.content': 'Nội dung tin nhắn của bạn',
    'contactus.content.tip': 'Hãy nhập điều bạn muốn nói',
    'contactus.sendding': 'Đang gửi, vui lòng đợi...',
    'contactus.success': 'Gửi thành công, cảm ơn tin nhắn của bạn, chúng tôi sẽ trả lời bạn trong thời gian sớm nhất',
};
