export default {
  'site.title': 'www.bogeqiu.com',
  'ball.all': 'tất cả',
  'ball.football': 'bóng đá',
  'ball.basketball': 'bóng rổ',
  'live.sport': 'các môn thể thao',
  'live.start_time': 'thời gian',
  'live.home_team_name': 'đội chủ nhà',
  'live.away_team_name': 'đội',
  'live.live': 'Kênh trực tiếp',
  'live.name': 'Trực tiếp',
  'live.more': 'Thêm',
  'live.result': 'Bộ sưu tập',
  'live.playback': 'Phát lại',
  'live.tobeupdated': 'Cập nhật',
  'foot.statement':
    'Tuyên bố đặc biệt: Trang web này là một nền tảng phát sóng trực tiếp các sự kiện thể thao toàn diện. Tất cả các nguồn phát sóng trực tiếp sự kiện của chúng tôi đều do bên thứ ba cung cấp. Chúng tôi không cung cấp bất kỳ video trực tiếp sự kiện độc quyền nào hoặc sản xuất bất kỳ dữ liệu trực tiếp sự kiện nào. Vui lòng tuân thủ luật pháp và quy định của địa phương. , vui lòng không sử dụng nó cho mục đích bất hợp pháp. Nếu bạn có bất kỳ câu hỏi nào hãy liên hệ với chúng tôi.',
};
