export default {
  'site.title': 'www.bogeqiu.com',
  'ball.all': 'tous',
  'ball.football': 'football',
  'ball.basketball': 'basket-ball',
  'live.sport': 'des sports',
  'live.start_time': 'temps',
  'live.home_team_name': 'équipe à domicile',
  'live.away_team_name': 'équipe extérieure',
  'live.live': 'Chaîne en direct',
  'live.name': 'Live',
  'live.result': 'Ensemble',
  'live.playback': 'Replay',
  'live.more': 'Plus',
  'live.tobeupdated': 'À mettre à jour',
  'foot.statement':
    "Déclaration spéciale : Ce site Web est une plate-forme complète de diffusion en direct d'événements sportifs. Toutes nos sources de diffusion en direct d'événements sont fournies par des tiers. Nous ne fournissons aucune vidéo en direct exclusive d'événements ni ne produisons de données en direct d'événements. Veuillez respecter les lois et réglementations locales. , veuillez ne pas l'utiliser à des fins illégales. Si vous avez des questions, veuillez nous contacter.",
};
