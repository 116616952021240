export default {
  'menu.index': '首页',
  'menu.result': '集锦',
  'menu.football': '足球直播',
  'menu.basketball': '篮球直播',
  'menu.live': '比分',
  'menu.contactus': '联系我们',
  'menu.language': '语言切换',
  'menu.skin.classics': '经典',
  'menu.skin.pink': '粉嫩',
};
