import contactus from './pt-PT/contactus';
import index from './pt-PT/index';
import menu from './pt-PT/menu';

export default {
  ...menu,
  ...index,
  ...contactus,
  '404': "Desculpe, a página que você visitou não existe.",
};
