export default {
  'menu.index': 'página delantera',
  'menu.football': 'futbol en vivo',
  'menu.basketball': 'baloncesto en vivo',
  'menu.live': 'puntaje',
  'menu.contactus': 'Contáctenos',
  'menu.language': 'cambio de idioma',
  'menu.skin.classics': 'Clásico',
  'menu.skin.pink': 'Rosa',
};
