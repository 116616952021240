import contactus from './vi-VN/contactus';
import menu from './vi-VN/menu';
import index from './vi-VN/index';

export default {
  ...menu,
  ...index,
  ...contactus,
  '404': "Rất tiếc, trang bạn đang truy cập không tồn tại.",
};
