export default {
  'menu.index': 'หน้าแรก',
  'menu.football': 'ฟุตบอลสด',
  'menu.basketball': 'บาสเก็ตบอลสด',
  'menu.live': 'คะแนน',
  'menu.contactus': 'ติดต่อเรา',
  'menu.language': 'สวิตช์ภาษา',
  'menu.skin.classics': 'คลาสสิค',
  'menu.skin.pink': 'สีชมพู',
};
