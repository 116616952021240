export default {
  'site.title': 'www.bogeqiu.com',
  'ball.all': 'todo',
  'ball.football': 'fútbol americano',
  'ball.basketball': 'baloncesto',
  'live.sport': 'Deportes',
  'live.start_time': 'tiempo',
  'live.home_team_name': 'equipo local',
  'live.away_team_name': 'equipo de fuera',
  'live.live': 'canal en vivo',
  'live.name': 'fuente',
  'live.result': 'Aspectos destacados',
  'live.playback': 'Reproducción',
  'live.more': 'Más',
  'live.tobeupdated': 'Pendiente de renovación',
  'foot.statement':
    'Declaración especial: este sitio web es una plataforma integral de transmisión en vivo de eventos deportivos. Todas nuestras fuentes de transmisión en vivo de eventos son proporcionadas por terceros. No proporcionamos ningún video exclusivo de eventos en vivo ni producimos datos en vivo de ningún evento. Respete las leyes y regulaciones locales. , no lo utilice con fines ilegales. Si tiene alguna pregunta, por favor contáctenos.',
};
