export default {
    'contactus.name': '당신의 이름',
    'contactus.name.tip': '당신의 이름을 입력 해주세요',
    'contactus.phone': '전화 번호',
    'contactus.phone.tip': '전화번호를 입력해주세요',
    'contactus.email': '당신의 사서함',
    'contactus.email.tip': '당신의 이메일 주소를 입력하십시오',
    'contactus.email.tip1': '유효한 이메일 주소를 입력하세요',
    'contactus.content': '귀하의 메시지 내용',
    'contactus.content.tip': '하고 싶은 말을 입력해주세요',
    'contactus.sendding': '제출 중입니다. 잠시 기다려 주세요...',
    'contactus.success': '제출이 완료되었습니다. 메시지를 보내주셔서 감사합니다. 최대한 빨리 답변해 드리겠습니다.',
};
