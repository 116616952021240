export default {
    'contactus.name': 'Namamu',
    'contactus.name.tip': 'Silahkan masukan nama anda',
    'contactus.phone': 'Nomor telepon Anda',
    'contactus.phone.tip': 'Masukkan nomor telepon anda',
    'contactus.email': 'kotak surat Anda',
    'contactus.email.tip': 'Silakan masukkan alamat email Anda',
    'contactus.email.tip1': 'silakan isi alamat email',
    'contactus.content': 'Isi pesan Anda',
    'contactus.content.tip': 'Silakan masukkan apa yang ingin Anda katakan',
    'contactus.sendding': 'Mengirimkan, harap tunggu...',
    'contactus.success': 'Pengiriman berhasil, terima kasih atas pesan Anda, kami akan membalas Anda sesegera mungkin',
};
