export default {
  'site.title': 'www.bogeqiu.com',
  'ball.all': 'inteiro',
  'ball.football': 'futebol',
  'ball.basketball': 'basquetebol',
  'live.sport': 'movimento',
  'live.start_time': 'tempo',
  'live.home_team_name': 'Equipa da casa',
  'live.away_team_name': 'Equipa visitante',
  'live.live': 'Canal ao Vivo',
  'live.name': 'fonte',
  'live.result': 'Colecção',
  'live.playback': 'Reprodução',
  'live.more': 'mais',
  'live.tobeupdated': 'A actualizar',
  'foot.statement':
    'Declaração especial: bogeqiu é uma plataforma abrangente de transmissão ao vivo de eventos desportivos, e todas as nossas fontes de transmissão ao vivo de eventos são fornecidas por terceiros. Não fornecemos vídeos exclusivos de transmissão ao vivo de eventos ou produzimos dados de transmissão ao vivo de eventos. Solicita-se aos internautas que cumpram as leis e regulamentos nacionais locais e não os utilizem para fins ilegais. Se você tiver alguma dúvida, entre em contato conosco.',
};
