export default {
  'menu.index': 'Página inicial',
  'menu.football': 'Transmissão ao vivo de futebol',
  'menu.basketball': 'Transmissão ao vivo de basquetebol',
  'menu.live': 'pontuação',
  'menu.contactus': 'entre em contato conosco',
  'menu.language': 'Mudança de idioma',
  'menu.skin.classics': 'clássico',
  'menu.skin.pink': 'Rosa',
};
