export default {
  'menu.index': '첫 장',
  'menu.football': '축구 라이브',
  'menu.basketball': '농구 라이브',
  'menu.live': '점수',
  'menu.contactus': '문의하기',
  'menu.language': '언어 스위치',
  'menu.skin.classics': '고전',
  'menu.skin.pink': '핑크',
};
