import { QuestionCircleOutlined, SkinOutlined } from '@ant-design/icons';
import { FormattedMessage, SelectLang as UmiSelectLang, useModel } from '@umijs/max';
import { Badge, Dropdown, MenuProps, Space, Typography } from 'antd';
import { useEffect, useState } from 'react';
const { listTimeZones } = require('timezone-support');

const { Text } = Typography;
export type SiderTheme = 'light' | 'dark';

export const SelectLang = () => {
  return (
    <UmiSelectLang
      reload={false}
      style={{
        padding: 4,
      }}
    />
  );
};

export const SelectCustomTheme = (props) => {
  const { initialState, setInitialState } = useModel('@@initialState');
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState(initialState?.customTheme);

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Space>
          <Badge color={'#04518e'}></Badge>
          <FormattedMessage id="menu.skin.classics" />
        </Space>
      ),
    },
    {
      key: '2',
      label: (
        <Space>
          <Badge color={'#94518e'}></Badge>
          <FormattedMessage id="menu.skin.pink" />
        </Space>
      ),
    },
  ];

  const onClick: MenuProps['onClick'] = ({ key }) => {
    localStorage.setItem('customTheme', key);
    setDefaultSelectedKeys(key);
    setInitialState((s) => ({
      ...s,
      customTheme: key,
    }));
  };

  return (
    <Dropdown
      placement="bottomRight"
      menu={{ items, onClick, selectable: true, defaultSelectedKeys: [defaultSelectedKeys] }}
    >
      <SkinOutlined />
    </Dropdown>
  );
};

export const SelectTimeZone = (props) => {
  const { initialState, setInitialState } = useModel('@@initialState');
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState(initialState?.defaultTimeZone);
  const [items, setItems] = useState<MenuProps['items']>([]);

  useEffect(() => {
    // 获取所有时区列表
    const allTimezones = listTimeZones();
    // 过滤出所有以 'Etc/GMT' 开头的时区
    const filteredTimezones = allTimezones.filter(
      (timezone) => !timezone.startsWith('Etc') && timezone.includes('/'),
    );

    // 根据洲分类时区
    const timezonesByContinent = filteredTimezones.reduce((acc, timezone) => {
      const [continent] = timezone.split('/');
      acc[continent] = acc[continent] || [];
      acc[continent].push(timezone);
      return acc;
    }, {});

    const items: MenuProps['items'] = Object.keys(timezonesByContinent).map((continent) => ({
      key: continent,
      label: continent,
      children: timezonesByContinent[continent].map((timezone) => ({
        key: timezone,
        label: timezone,
      })),
    }));

    setItems(items);
  }, []);

  const onClick: MenuProps['onClick'] = ({ key }) => {
    localStorage.setItem('timezone', key);
    setDefaultSelectedKeys(key);
    setInitialState((s) => ({
      ...s,
      defaultTimeZone: key,
    }));
  };

  return (
    <Dropdown
      placement="bottomRight"
      menu={{ items, onClick, selectable: true, defaultSelectedKeys: [defaultSelectedKeys] }}
    >
      <Text>{initialState?.defaultTimeZone}</Text>
    </Dropdown>
  );
};

export const Question = () => {
  return (
    <div
      style={{
        display: 'flex',
        height: 26,
      }}
      onClick={() => {
        window.open('https://pro.ant.design/docs/getting-started');
      }}
    >
      <QuestionCircleOutlined />
    </div>
  );
};
