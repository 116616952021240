import contactus from './es-ES/contactus';
import menu from './es-ES/menu';
import index from './es-ES/index';

export default {
  ...menu,
  ...index,
  ...contactus,
  '404': 'Lo siento, la página a la que visita no existe.',
};
