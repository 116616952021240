import contactus from './it-IT/contactus';
import menu from './it-IT/menu';
import index from './it-IT/index';

export default {
  ...menu,
  ...index,
  ...contactus,
  '404': "Spiacenti, la pagina che hai visitato non esiste.",
};
