export default {
    'contactus.name': 'あなたの名前',
    'contactus.name.tip': 'あなたの名前を入力してください',
    'contactus.phone': 'あなたの電話番号',
    'contactus.phone.tip': '電話番号を入力してください',
    'contactus.email': 'あなたのメールボックス',
    'contactus.email.tip': 'メールアドレスを入力してください',
    'contactus.email.tip1': '有効なメールアドレスを入力してください',
    'contactus.content': 'あなたのメッセージの内容',
    'contactus.content.tip': '言いたいことを入力してください',
    'contactus.sendding': '送信中です。お待​​ちください...',
    'contactus.success': '送信は成功しました。メッセージありがとうございます。できるだけ早く返信させていただきます。',
};
