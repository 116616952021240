export default {
    'contactus.name': 'ชื่อของคุณ',
    'contactus.name.tip': 'กรุณากรอกชื่อของคุณ',
    'contactus.phone': 'หมายเลขโทรศัพท์ของคุณ',
    'contactus.phone.tip': 'กรุณากรอกหมายเลขโทรศัพท์ของคุณ',
    'contactus.email': 'กล่องจดหมายของคุณ',
    'contactus.email.tip': 'กรุณากรอกอีเมลของคุณ',
    'contactus.email.tip1': 'กรุณาใส่อีเมล์ที่ถูกต้อง',
    'contactus.content': 'เนื้อหาข้อความของคุณ',
    'contactus.content.tip': 'กรุณากรอกสิ่งที่คุณต้องการจะพูด',
    'contactus.sendding': 'กำลังส่ง กรุณารอสักครู่...',
    'contactus.success': 'ส่งสำเร็จ ขอบคุณสำหรับข้อความของคุณ เราจะตอบกลับคุณโดยเร็วที่สุด',
};
