export default {
  'menu.index': 'ホームページ',
  'menu.football': 'サッカーライブ',
  'menu.basketball': 'バスケットボールのライブ',
  'menu.live': 'スコア',
  'menu.contactus': 'お問い合わせ',
  'menu.language': '言語スイッチ',
  'menu.skin.classics': 'クラシック',
  'menu.skin.pink': 'ピンク',
};
